.search-form {
  position: relative;
}
.search-form .form-button {  
  background-color: #316E8D;
  color: white;
  border: 1px solid #316E8D;  
}
.search-form .form-button:hover {
  background-color: white;
  color: #316E8D;
}
