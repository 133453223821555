.table-log-container{
    background-color: #FFF;
    margin: 15px 0;
    padding: 10px 15px;
    box-shadow: 1px 4px 6px -3px rgb(0 0 0 / 69%);
    -moz-box-shadow: 1px 4px 6px -3px rgba(0,0,0,.69);
}
.table-log-container button{
    background-color: #3f51b5;
    color: #ffffff;
    border: none;
    margin-right: 10px;
    min-width: 40px;
    height: 35px;
    cursor: pointer;
}
.table-log-container button:hover{
    background-color: #303f9f;
}
table{
    width: 100%;
    margin-bottom: 30px;
}
table td, table th{
    padding: 15px 5px;
}
table td, table th {    
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.page-count{
    min-width: 100px;   
    margin: auto 0;
}
.go-to-page{
    display: inline-block;
    margin: auto 0;
}
.go-to-page input{
    max-width: 100px;
    display: inline-block;
    font-size: inherit;
    padding: 0;
    color: #000000;
}
.items-count{
    max-width: 100px;
    border: none;
    margin: 0 15px;
    font-size: inherit;
    padding: 0;
}
.pagination{
    flex-wrap: wrap;
}
.table-log-container a, .table-log-container a:hover{
    color: #3f51b5;
}
