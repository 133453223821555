.validation-form {
  position: relative;
}
.validation-form .form-button {
  width: 100%;
  background-color: #316E8D;
  color: white;
  border: 1px solid #316E8D;
  font-size: 1.3rem;
}
.validation-form .form-button:hover {
  background-color: white;
  color: #316E8D;
}
.validation-form input {
  color: #000000;
}
.validation-form .form-error {
  font-size: 1.3rem;
  color: #f00;
  line-height: 1;
  min-height: 1.3rem;
  margin-bottom: 10px;
}
.validation-form .spinner-container {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
}
