.validation-form-container .form-link {
  color: black;
  text-decoration: underline;
}
.validation-form-container .form-link:hover {
  color: #316E8D;
}
.validation-form-container .form-container {
  padding-top: 15px;
}
.validation-form-container .top-block-container {
  margin-bottom: 100px;
}
.validation-form-container .bottom-block-container {
  text-align: center;
}
.validation-form-container .bottom-block-container .footer-links a {
  font-size: 1.3rem;
  margin: 0 10px;
}
@media (max-width: 767px) {
  .validation-form-container .top-block-container {
    margin-bottom: 0;
  }
}
